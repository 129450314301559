import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/section-connect-post.css'
import '../styles/style.css';
import self_portrait_new from '../imgs/self_portrait_new.png'

function IntroduceCreatorSection() {
  return (
    <>
      <div id="section-creator"></div>
      <section className="section-creator" >
            <div className="section-creator-wrapper">
                <div className="row">
                    <div className="section-creator-wrapper__img col-md-6" style={{zIndex: 100}}>
                        <img src={self_portrait_new} alt="portrait"/>
                    </div>
                    <div className="col-md-6 right-container">
                        <h3>The Creator</h3>
                        <h5>Mauricio H Vanegas</h5>
                        <p>
                            Mauricio is an innovative and conceptual graphic designer and photographer from Orlando, FL with 15+ years of experience focused on multi-channel marketing, creative/art direction, internet presence, and print production. He is a strategic thinker and
                            team player with extensive knowledge in the graphic industry. After finishing high school (1989), he created 3K-O-89 (originally named TRIKO) out of wood and acrylics to amuse friends and family. The character's success inspired him to build a business around it, and now 3K-O-89 is coming to the NFT world to create a legacy for future generations.
                        </p>
                    </div>
                </div>
            </div>
            <div id="section-team"></div>
        </section>
    </>        
  )
}

export default IntroduceCreatorSection
