import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap.min.js'
import logo from '../imgs/3ko-89_logo_site.png'
import '../styles/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWallet,
  faCartShopping,
  faBars
} from '@fortawesome/free-solid-svg-icons'

export default function Navbar (props) {
  const [isClickedConnect, setIsClickedConnect] = useState(false)

  const clickedNavBtn = () => {
    if (isClickedConnect === false) {
      props.getDataFromChild(true)
    }
    setIsClickedConnect(true)
  }

  return (
    <div className='menu-wrapper'>
      {/**Logo */}
      <a href='#section_post_content'>
        <img className='site-logo' src={logo} alt="logo"/>
      </a>
      {/*  <!--  Normal Menu -->*/}
      <nav className='main-menu' aria-label='Main Menu'>
        <ul>
          <li className='normal movilnone'>
            <a className='menu-item ' href='#section-hello'>
              HISTORY
            </a>
          </li>
          <li className='normal movilnone'>
            <a href='#section-choosenft' className='  menu-item '>
              MINT YOUR NFT
            </a>
          </li>
          <li className='normal movilnone'>
            <a className=' menu-item' href='https://3ko89.com/'>
              ROAD MAP
            </a>
          </li>
          <li className='normal movilnone'>
            <a className=' menu-item' href='#section-creator'>
              CREATOR
            </a>
          </li>
          <li className='normal movilnone'>
            <a className=' menu-item' href='#section-team'>
              TEAM
            </a>
          </li>
          <li className='normal movilnone'>
            <a className=' menu-item' href='/faqs.html'>
              FAQs
            </a>
          </li>
          <li className='normal d-flex justify-content-space-around'>
            <a
              href='#'
              className='btn-connect-wallet d-flex align-items-center'
              onClick={() => {
                clickedNavBtn()
              }}
            >
              {' '}
              <FontAwesomeIcon
                icon={faWallet}
                className='fa-solid fa-wallet pe-4'
              />
              <span>
                {' '}
                {(((props.clickedBtnNav || props.clicked) && props.account)|| props.account)
                  ? 'CONNECTED'
                  : 'CONNECT WALLET'}
              </span>
            </a>
          </li>
          <li className='normal d-none' >
            {' '}
            <a
              className='menu-item icon btn-connect-cart-shopping d-flex align-items-center '
              href='#'
            >
              <FontAwesomeIcon
                icon={faCartShopping}
                className='fa-solid fa-cart-shopping ml-0 px-3'
              />
            </a>
          </li>

          <li className='mobile '>
            <a
              className='menu-item icon'
              role='button'
              id='more-menu'
              href='#'
              onClick={props.clickMe}
            >
              <FontAwesomeIcon icon={faBars} className='fa-solid fa-bars' />
            </a>
          </li>
        </ul>
      </nav>
      {/* Dropdown Menu for mobile */}
      <div id='expand-menu' className={props.dropdown ? 'show' : null}>
        <ul>
          <li>
            <a className='menu-item ' href='#section-hello'>
              HISTORY
            </a>
          </li>
          <li>
            <a className='menu-item' href='#section-creator'>
              MINT YOUR NFT
            </a>
          </li>
          <li>
            <a className='menu-item' href='#section-roadmap'>
              ROAD MAP
            </a>
          </li>
          <li>
            <a className='menu-item' href='#section-choosenft'>
              CREATOR
            </a>
          </li>
          <li>
            <a className='menu-item' href='#section-team'>
              TEAM
            </a>
          </li>
          <li>
            <a className='menu-item' href='/faqs.html'>
              FAQs
            </a>
          </li>
          <li>
            <a className='menu-item' href='#'>
              CONNECT WALLET
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
