import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from '../redux/blockchain/blockchainActions'
import { fetchData } from '../redux/data/dataActions'
import '../styles/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import img3ko89logowhite from '../imgs/3ko-89_logo_white.png'
import imgs3KO89slideshowgif from '../imgs/3K-O-89-slideshow+02-21-22.gif'
import '../styles/section-mint.css'

function SectionMinted (props) {
  
  const [connectClicked, setConnectClicked] = useState(false)
  const { ethereum } = window
  const dispatch = useDispatch()
  const blockchain = useSelector(state => state.blockchain)
  const data = useSelector(state => state.data)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [mintAmount, setMintAmount] = useState(1)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    WEI_COST_PRESALE: 0,
    WEI_COST_WHITELIST: 0,
    DISPLAY_COST: 0.00,
    DISPLAY_COST_PRESALE: 0.00,
    DISPLAY_COST_WHITELIST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    SHOW_BACKGROUND: false,
    MAXMINT: 0
  })
  const [account, setCurrentAccount] = useState()
  const [whiteList, setWhiteList] = useState(false)

  const [hasDisc, sethasDisc] = useState(false)
  const [discinput, setDiscInput] = useState()
  const{errorMsg, seterrMsg} = useState()
  const [textHere, setTextHere] =useState('')
  
  const [textConnectButton, setTextConnectButton] = useState("") 

  const changeErrMsg =  () => {
    if((blockchain.account ==='' || blockchain.smartContract===null) && (blockchain.errMsg!=='')){
      const errmsg = blockchain.errMsg
      setFeedback("Please check your wallet connection and retry again.")
      //seterrMsg({errmsg})
    }
    return errorMsg
  } 

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST
    let cost_presale = CONFIG.WEI_COST_PRESALE
    let cost_whitelist = CONFIG.WEI_COST_WHITELIST
    let gasLimit = CONFIG.GAS_LIMIT 
    let totalGasLimit = String(gasLimit * mintAmount)
    let totalCostWei =
      (props.fechaWhiteList && hasDisc)
        ? String(cost_whitelist * mintAmount)
        : props.presale
        ? String(cost_presale * mintAmount)
        : String(cost * mintAmount)
    setFeedback(`Minting ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    if (props.fechaWhiteList && hasDisc) {
      let discordCodeMint = discount_input(discinput)
      try {
          blockchain.smartContract.methods
            .mintWhitelist(mintAmount, discordCodeMint)
            .send({
              gasLimit: String(totalGasLimit),
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
              value: totalCostWei
            })
            .once('error', err => {
              setClaimingNft(false)
              setFeedback('Sorry, something went wrong please try again later.')
              dispatch(fetchData(blockchain.account))
              whitelist_done(false)
              sethasDisc(false)
              setTextConnectButton("BUY NOW")
            })
            .then(receipt => {
              setFeedback(
                `Congrats! Your Transaction is Completed View it `
              )
              setTextHere(' Here')
              setClaimingNft(false)
              dispatch(fetchData(blockchain.account))
              whitelist_done(false)
              sethasDisc(false)
              setTextConnectButton("BUY NOW")
            }) 
      } catch (error) {
            setFeedback('Sorry, please press, CTRL+F5 or F5 and try again.')    
            setClaimingNft(false)
      }
    } else if (props.presale) {
      try 
      {
        blockchain.smartContract.methods
          .mintPresale(mintAmount)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei
          })
          .once('error', err => {
            setFeedback('Sorry, something went wrong please try again later.')
            setClaimingNft(false)
            setTextConnectButton("BUY NOW")
          })
          .then(receipt => {
            setFeedback(
              `Congrats! Your Transaction is Completed View it  `
            )     
            setTextHere(' Here')
            setClaimingNft(false)
            dispatch(fetchData(blockchain.account))
            setTextConnectButton("BUY NOW")
          })
      }
      catch(error)    
      {
        setFeedback('Sorry, please press, CTRL+F5 or F5 and try again.')    
        setClaimingNft(false)
      }
    } else {
      try
      {      
        blockchain.smartContract.methods
          .mintGeneral(mintAmount)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei
          })
          .once('error', err => {
            setTextConnectButton("BUY NOW")
            setFeedback('Sorry, something went wrong please try again later.')
            setClaimingNft(false)
          })
          .then(receipt => {
            setFeedback(
              `Congrats! Your Transaction is Completed View it `
            )
            setTextHere(' Here')
            setClaimingNft(false)
            dispatch(fetchData(blockchain.account))
            setTextConnectButton("BUY NOW")
          })
      }
      catch(error)    
      {
        setFeedback('Sorry, please press, CTRL+F5 or F5 and try again.')    
        setClaimingNft(false)
      }
    }
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1
    if (newMintAmount < 1) {
      newMintAmount = 1
    }
    setMintAmount(newMintAmount)
  }

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1
    if (newMintAmount > CONFIG.MAXMINT) {
      newMintAmount = CONFIG.MAXMINT
    }
    setMintAmount(newMintAmount)
  }

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    } else {
      {
        setFeedback('')
      }
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch('../config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }
  /**btn navbar clicked */
  useEffect(() => {
    if (props.clickedBtnNav === true) {
      dispatch(connect())
      getData()
      setConnectClicked(true)
       setFeedback(`Click the "Buy Button" to Mint your NFTs.`)
    }
  }, [props.clickedBtnNav])

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    getData()
  }, [blockchain.account])

  /**coger valor del input code en Discount box */
  const discount_input = value => {
    setDiscInput(value)
    props.funcGetDiscInput(value);
    return value
  }

  const whitelist_done = value => {
    setWhiteList(value)
    return value
  }

  const validateCode = () => {
    if ((blockchain.account === undefined) || (blockchain.account === null)||(blockchain.account === '')) {
      setFeedback('first connect to the network before validating')
      return false
    }
    if ((discinput === undefined) || (discinput === null)|| (discinput === '')) {
      setFeedback('invalid discord code, enter it again')
      return false
    }
    const account = blockchain.account
    const valido = props.keyResponse 
 
    if (valido) {
      sethasDisc(true) 
      setWhiteList(true) 
      setFeedback('validated code ready to use. * C a u t i o n: Only one use.!*')
      setMintAmount(1)
    } else {
      sethasDisc(false) 
      setFeedback('validated code wrong')
    }
    return hasDisc
  }
  
  const connectBuy = () => {
    claimNFTs()
    getData()
  }



   const handleConnect = () => {
   
    try{
        if (connectClicked === false) {
            dispatch(connect())
            getData()
            props.getDataFromChild(true)
            props.handleChange(true)
            if (((props.clickedBtnNav || props.clicked) && props.account) || props.account){
              setTextConnectButton("BUY NOW")
              setFeedback(`Click buy to mint your NFT.`)
              setConnectClicked(true)
            }else{
              //setTextConnectButton("CONNECT WALLET")
              setTextConnectButton("BUY NOW")
              return
            }
            setConnectClicked(true)
        } else {
          if (textConnectButton==="BUY NOW" || textConnectButton==="") {
            connectBuy()
            setTextConnectButton("Please Wait Sale in Progress")
          }  
        }
    }catch(err){
        setFeedback('Sorry, please press, CTRL+F5 or F5 and try again.'+ err)
        getData()
    }
  }

  return (
    <section className='section-minted py-5 mint col-12'>
      <section className='mint'>
        <div className='container'>
          <div className='heading'>
            <img alt="img3ko89logowhite"
              className='section-post-content-site-logo'
              src={img3ko89logowhite}
            />
          </div>
          <div className='mint-box'>
            <div className='mint-box-container'>
              <p className='minted-p'>
                {' '}
                {data.totalSupply} / {CONFIG.MAX_SUPPLY} Minted
              </p>
              <p className='mint-box-container__p'>
                <a target={'_blank'} href={CONFIG.SCAN_LINK}>
                  {CONFIG.CONTRACT_ADDRESS}
                </a>
              </p>
              <div className='nft-info-box d-flex flex-sm-row flex-column'>
                <div className='nft-info-box__img '>
                  <img src={imgs3KO89slideshowgif} alt='3ko89_nft' />
                </div>
                <div className='nft-info-box-right col-md-8'>
                  <p id='nft-info__active-presale'>
                    {(props.fechaWhiteList && hasDisc)
                      ? 'WHITELIST'
                      : (props.presale)
                      ? 'ACTIVE PRE-SALE'
                      : 'LIVE SALE'}
                  </p>
                  <p style={{ lineHeight: '1' }}>{CONFIG.NFT_NAME}</p>
                   <p>Minting Price</p>
                  <h4>
                    {(props.fechaWhiteList && hasDisc)
                      ? CONFIG.DISPLAY_COST_WHITELIST
                      : (props.presale)
                      ? CONFIG.DISPLAY_COST_PRESALE
                      : CONFIG.DISPLAY_COST}{' '}
                    {CONFIG.NETWORK.SYMBOL}
                  </h4>
                  <p> <a href={CONFIG.MARKETPLACE_LINK} target='_blank'>
                    {data.totalSupply >= CONFIG.MAX_SUPPLY
                        ? ' The sale has ended.'
                        : `Also Available in `+CONFIG.MARKETPLACE+``}
                  </a>
                  </p>
                </div>
              </div>
              <div className='quantity-box'>
                <div className='quantity-box-left'>
                  <button
                    disabled={claimingNft ? 1 : 0}
                    onClick={e => {
                      e.preventDefault()
                      decrementMintAmount()
                    }}
                    style={{ display: (props.fechaWhiteList && hasDisc) ? 'none' : 'block' }}
                  >
                    <svg
                     
                      viewBox='0 0 27 27'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.5 24.3C16.3643 24.3 19.1114 23.1621 21.1368 21.1368C23.1621 19.1114 24.3 16.3643 24.3 13.5C24.3 10.6357 23.1621 7.88864 21.1368 5.86325C19.1114 3.83785 16.3643 2.7 13.5 2.7C10.6357 2.7 7.88864 3.83785 5.86325 5.86325C3.83785 7.88864 2.7 10.6357 2.7 13.5C2.7 16.3643 3.83785 19.1114 5.86325 21.1368C7.88864 23.1621 10.6357 24.3 13.5 24.3ZM13.5 27C6.04395 27 0 20.9561 0 13.5C0 6.04395 6.04395 0 13.5 0C20.9561 0 27 6.04395 27 13.5C27 20.9561 20.9561 27 13.5 27ZM6.75 12.15H20.25C20.608 12.15 20.9514 12.2922 21.2046 12.5454C21.4578 12.7986 21.6 13.142 21.6 13.5C21.6 13.858 21.4578 14.2014 21.2046 14.4546C20.9514 14.7078 20.608 14.85 20.25 14.85H6.75C6.39196 14.85 6.04858 14.7078 5.79541 14.4546C5.54223 14.2014 5.4 13.858 5.4 13.5C5.4 13.142 5.54223 12.7986 5.79541 12.5454C6.04858 12.2922 6.39196 12.15 6.75 12.15Z'
                        fill='#fff'
                      ></path>
                    </svg>
                  </button>
                  <p>{(props.fechaWhiteList && hasDisc) ? 1 : mintAmount}</p>
                  <button
                    disabled={claimingNft ? 1 : 0}
                    onClick={e => {
                      e.preventDefault()
                      incrementMintAmount()
                    }}
                    style={{ display: (props.fechaWhiteList && hasDisc) ? 'none' : 'block' }}
                  >
                    <svg
                     /* width='27'
                      height='27'*/
                      viewBox='0 0 27 27'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.5 27C6.04395 27 0 20.9561 0 13.5C0 6.04395 6.04395 0 13.5 0C20.9561 0 27 6.04395 27 13.5C27 20.9561 20.9561 27 13.5 27ZM13.5 24.3C16.3643 24.3 19.1114 23.1621 21.1368 21.1368C23.1621 19.1114 24.3 16.3643 24.3 13.5C24.3 10.6357 23.1621 7.88864 21.1368 5.86325C19.1114 3.83785 16.3643 2.7 13.5 2.7C10.6357 2.7 7.88864 3.83785 5.86325 5.86325C3.83785 7.88864 2.7 10.6357 2.7 13.5C2.7 16.3643 3.83785 19.1114 5.86325 21.1368C7.88864 23.1621 10.6357 24.3 13.5 24.3ZM14.85 14.85V20.25C14.85 20.608 14.7078 20.9514 14.4546 21.2046C14.2014 21.4578 13.858 21.6 13.5 21.6C13.142 21.6 12.7986 21.4578 12.5454 21.2046C12.2922 20.9514 12.15 20.608 12.15 20.25V14.85H6.75C6.39196 14.85 6.04858 14.7078 5.79541 14.4546C5.54223 14.2014 5.4 13.858 5.4 13.5C5.4 13.142 5.54223 12.7986 5.79541 12.5454C6.04858 12.2922 6.39196 12.15 6.75 12.15H12.15V6.75C12.15 6.39196 12.2922 6.04858 12.5454 5.79541C12.7986 5.54223 13.142 5.4 13.5 5.4C13.858 5.4 14.2014 5.54223 14.4546 5.79541C14.7078 6.04858 14.85 6.39196 14.85 6.75V12.15H20.25C20.608 12.15 20.9514 12.2922 21.2046 12.5454C21.4578 12.7986 21.6 13.142 21.6 13.5C21.6 13.858 21.4578 14.2014 21.2046 14.4546C20.9514 14.7078 20.608 14.85 20.25 14.85H14.85Z'
                        fill='#fff'
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className='quantity-box-right'>
                  <p>{(props.fechaWhiteList && hasDisc) ? 1 : CONFIG.MAXMINT} MAX</p>
                </div>
              </div>
              <>
                {(props.fechaWhiteList)? (
                  <div className='discount-box d-flex flex-column text-white bolder'>
                    <div className=' discount-box_div d-flex flex-column flex-lg-row   flex-sm-row flex-md-row align-items-center'>
                      <p className='col-sm-5 discount-box__code'>
                        DISCORD CODE:
                      </p>
                      <form action='' className='col-sm-7'>
                        <input
                          type='text'
                          onChange={e => {
                            discount_input(e.target.value)
                          }}
                          className='  form-control'
                          id='discount-box__input'
                        />
                      </form>
                    </div>
                    <div className="discount-box_divBtn">
                      <div >
                        {(connectClicked && (!hasDisc)) ? (
                          <button
                            onClick={e => {
                              validateCode()
                            }}
                            value='Validate'
                            className=' col-md-6 form-control '
                            id='validateDiscord'
                          >
                            Validate Code{' '}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </>

              <div className='total-box'>
                <p>TOTAL</p>
                <p>
                  {(mintAmount *
                        ((props.fechaWhiteList && hasDisc)
                      ? CONFIG.DISPLAY_COST_WHITELIST
                      : (props.presale)
                      ? CONFIG.DISPLAY_COST_PRESALE
                      : CONFIG.DISPLAY_COST)).toFixed(2)}{' '}
                  ETH
                </p>
              </div>
              <div
                className='connect'
                style={{ color: '#fff', textAlign: 'center' }}
              >
                <p id='connect__p'>
                  {!connectClicked
                    ? `Connect to the   ${CONFIG.NETWORK.NAME} network`
                    : feedback } 
                   <a href={CONFIG.MARKETPLACE_LINK} target="_blank"> {textHere} </a>
                </p>
                <div style={{ color: '#fff', textAlign: 'center' }}>
                  <span> Gas fees are not included.</span>
                  <div style={{ color: '#fff', textAlign: 'center' }}>
                    <button
                      className='mint-btn'
                      onClick={e => {
                        handleConnect()
                      }}
                      style={{ backgroundColor: '#25b24c', color: '#fff' }}
                    >  {(((props.clickedBtnNav || props.clicked) && props.account && textConnectButton!== "")|| props.account)
                    ? (textConnectButton===""?"BUY NOW":textConnectButton)
                    : 'CONNECT WALLET'}{' '}
                    {}
                    </button>
                    <p style={{ color: '#fff', textAlign: 'center' }}>
                      {' '}
                    </p>
                    <p className="infoF5" style={{ color: '#fff', textAlign: 'center' }}>
                      If Site is Inactive, Please Press CTRL + F5
                    </p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="section-choosenft"></div>
      </section>
    </section>
  )
}

export default SectionMinted
