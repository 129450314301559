import React from 'react'
import '../styles/reset.css'
import '../styles/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/section-back-in-time.css' 


function BackinTimesection() {
  return (
    <section className="section-back-in-time">
            <div className="container">
                <h3>Back in Time</h3>
                <p>
                    In 1989, the original 3K-O-89 3D character was created and made of balsa-wood by the Colombian, Mauricio Vanegas, to amuse family and friends.
                </p>
                <p>
                    After the character’s success and admiration, multiple variations of the 3K-O-89 were created. Each has sold in fine souvenir and greeting card stores around the city.
                </p>
                <p>
                    With financial optimism and aggressive growth, the 3K-O-89 has moved on from front city stores into NFTs.
                </p>
            </div>
        </section>
  )
}

export default BackinTimesection