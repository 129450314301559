import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/section-post-whitelist-post.css'
import '../styles/style.css';
import video3ko89 from '../video/3K-O-89_3D-Turntable.mp4'

function HelloSection() {
    return (
        <section className="section-hello" >
            <div className="section-whitelist-container">
                <div className="row">
                    <figure className="col-sm-6 section-whitelist__img">
                        <video controls>
                            <source src={video3ko89} type="video/mp4"/>
                            Your browser does not support HTML video.
                        </video>
                        <figcaption className="img-section-hello__figcaption">3K-O-89 Master 2024
                                <span>3D Model - Available Soon</span>
                        </figcaption>
                    </figure>
                    <div className="col-sm-6 right-container" >
                        <h3 className="section-hello__title">Hello, I’m 3K-O-89.</h3>
                        <h5>I’m holding the magic keys for higher dimensions.</h5>
                       <p > 3K-O-89 is a Vintage robot from 1989 that has come back to earth to share the keys for higher dimensions. The adorable robot could amuse the audience with different outfits, while the ornaments and magical items he is holding inform others about his mystical world.
                        </p>
                    </div>
                </div>
            </div>  
        </section>
    )
}

export default HelloSection
