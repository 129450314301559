import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../styles/section-choose-nft.css'
import '../styles/style.css'
import Images from './Images'

function NFTsection(props) {
  return (
        <div  className="carousel slide section-choose-nft"  data-bs-ride="carousel">
            <h3>Choose your NFT</h3>
            <div className="nft-slider d-flex flex-row">
                            <Images key={props.key} className="carousel__img"/>
            </div>
            <div className="my-5 btn-section-discord-wrapper text-center mt-5 pt-4"><a href="https://makersplace.com/3ko89/shop?" target="_blank" className="btn-section-discord">Buy Yours Now!</a></div>
        </div>
  )
}

export default NFTsection
