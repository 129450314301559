import React from 'react'
import '../styles/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/footer.css'
import logowfooter from '../imgs/logo_w-footer.png'
import { FaInstagram, FaDiscord, FaTwitterSquare  } from 'react-icons/fa';
import NewsletterSubscribe from '../NewsletterSubscribe'

function Footer() {
  return (
    <footer id="footer">
            <div className="row container">
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                       
                        <form >
                            <label htmlFor="email" className="required">Email:</label>
                            <div className="small-desc">Subscribe to Our Mailing List</div>
                              <NewsletterSubscribe url=""/>
                        </form>
                    </div>
                </div>

                <div className="footer__logo col-md-4 col-sm-6">
                    <img src={logowfooter} className="w-100" alt="logowfooter" />
                </div>

                <div className="col-md-4 col-sm-12 text-center my-4">
                    <div className="get_social">GET SOCIAL</div>
                    <div className="social-icons d-flex flex-row justify-content-center align-items-center">
                        
                        <a href="https://twitter.com/3ko89" target="_blank">
                           <FaTwitterSquare className="fa-brands fa-twitter-square"/>
                        </a>
                        
                        <a href="https://www.instagram.com/3ko_89" target="_blank">
                            <FaInstagram className="fa-brands fa-instagram"/>
                        </a>

                    </div>
                    <div>
                        <a href="/terms-conditions.html" className="terms">Terms and Conditions</a>
                    </div>
                </div>
            </div>
            <div className="copy-right text-center pt-3 pb-1">
                © Copyright 1989 - 2024 | All Rights Reserved
            </div>
        </footer>
  )
}

export default Footer
