import React from 'react'
import '../styles/globalStyles'
import '../styles/style.css'
import '../styles/section-connect-post.css'
import 'bootstrap/dist/css/bootstrap.min.css';


function ConnectDescriptionSection() {
  return (
        <section className="section-connect-post">
            <div className="section-connect-post-text-wrapper">
                <h3>Connect! Bring everybody together.</h3>
                <p>
                    The 3K-O-89 is a collection of 10,000 unique robot NFTs with different outfits based on the history of the greatest human empires and beyond.
                </p>
                <p>
                    Each 3K-O-89 is unique since it was created 33 years ago and has evolved and acquired characteristics for today’s time. 3K-O-89 is based on hundreds of well-designed elements that characterize a bullish crypto enthusiast.
                </p>
                <p>
                    Understanding 3K-O-89 means joining a family full of people who believe in the future of cryptocurrencies and blockchain technology.
                </p>
            </div>
        </section>
  )
}

export default ConnectDescriptionSection