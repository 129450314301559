import React from 'react'
import team1 from '../imgs/team-1.png' 
import team2 from '../imgs/team-2.png' 
import team3 from '../imgs/team-3.png' 
import team4 from '../imgs/team-4.png' 
import team5 from '../imgs/team-5.png' 
import team6 from '../imgs/team-6.png' 
import team7 from '../imgs/team-7.png' 
import team8 from '../imgs/team-8.png' 
import '../styles/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/section-team.css'

function TeamSection() {
  return (
       <section className="section-team" >
            <h2 className="text-center">3K-O-89 Team</h2>
            <div className="p-wrapper mx-auto">
                <p>
                    Here is the team that is working on this project under the creator’s management.
                </p>
                <p>
                    Team members taking care of our social media, discord, marketing, and more:
                </p>
            </div>
            {/*<!--  Introduce Artists -->*/}
            <h2 className="text-center artist">Artists</h2>
            <hr />
            <div className="row">
                <div className="col-md-6 text-end">
                    <div className="card-wrappper float-end me-5">
                        <img src={team7} alt="team7" />
                        <div className="skill">Coloring&Style Artist</div>
                        <div className="username">Surigiarto Reski</div>
                        <div className="where">Illustrator from Indonesia</div>
                    </div>
                </div>
                <div className="col-md-6 text-start">
                    <div className="card-wrappper ms-5">
                        <img src={team8} alt="team8"/>
                        <div className="skill">3D Artist</div>
                        <div className="username">Cesar Correa Lopez</div>
                        <div className="where">Concept Artist from Colombia</div>
                    </div>
                </div>
            </div>

           {/*} <!--  Introduce Team members -->*/}
            <h2 className="text-center artist">Team</h2>
            <hr />

            <div className="row my-5">
                <div className="col-md-4">
                    <div className="card-wrappper mx-auto">
                        <img src={team1} alt="team1"/>
                        <div className="skill">Computer Engineer</div>
                        <div className="username">Billy Vanegas</div>
                        <div className="where">Blockchain Dev. from Spain</div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card-wrappper mx-auto">
                        <img src={team2} alt="team2"/>
                        <div className="skill">Social Media Manager</div>
                        <div className="username">Andrea Vanegas</div>
                        <div className="where">S.M. Manager from Spain</div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card-wrappper mx-auto">
                        <img src={team3} alt="team3"/>
                        <div className="skill">Digital Marketing</div>
                        <div className="username">Sergio Vanegas</div>
                        <div className="where">Videographer from Florida</div>
                    </div>
                </div>
            </div>

            <div className="row my-5">
                <div className="col-md-4">
                    <div className="card-wrappper mx-auto">
                        <img src={team4} alt="team4" />
                        <div className="skill">Network Security</div>
                        <div className="username">Jean Paul Vanegas</div>
                        <div className="where">Servers Dev. from Andorra</div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card-wrappper mx-auto">
                        <img src={team5} alt="team5" />
                        <div className="skill">Social Media Marketing</div>
                        <div className="username">Samatha Vanegas</div>
                        <div className="where">From Andorra</div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card-wrappper mx-auto">
                        <img src={team6} alt="team6"/>
                        <div className="skill">Administrative Secretary</div>
                        <div className="username">Luz Tellez</div>
                        <div className="where">From Spain</div>
                    </div>
                </div>

            </div>
        </section>
  )
}

export default TeamSection
