import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/style.css';
import '../styles/section-post-whitelist-post.css'
import gatekeeper from '../imgs/Gatekeeper_01.png'

function WhitelistSection() {
  return (
    <section className="section-whitelist">
                <div className="section-whitelist-container">
                    <div className="section-whitelist-container__box">
                        <div className="section-whitelist-container__img col-md-6">
                            <img className="section-whitelist-img-left" src={gatekeeper} alt="gatekeeper"/>
                        </div>
                        <div className="col-md-6 section-right d-flex flex-column align-items-baseline justify-content-center">
                            <h3>Also Available in Markersplace</h3>
                            <p>Make a collection offer or buy individual</p>
                            <div className="w-100 py-5 btn-section-whitelist-container">
                                <a href="https://makersplace.com/3ko89/shop?" target="_blank" className="btn-section-whitelist" id="section-hello">Click Here to Buy in Markersplace</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  )
}

export default WhitelistSection
