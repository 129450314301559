import React ,{useEffect}from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/style.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap.min.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp} from '@fortawesome/free-solid-svg-icons'

function TopScroll(props) {
  
  useEffect ( ()=> {
    document.querySelector(".root").addEventListener('scroll', (e) => {
    if (e.target.scrollTop > 100) {
        document.querySelector("#top_scroll_btn").classList.add("show");
    } else {
        document.querySelector("#top_scroll_btn").classList.remove("show");
    }
    })
});

  return (
    <div className="top-scroll"       id="top_scroll_btn" onClick={props.scrolltop} >
       <FontAwesomeIcon icon={faAngleUp} className="fa-solid fa-angle-up" />
    </div>
  )
}

export default TopScroll