import React from 'react'
import '../styles/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/footer.css'
import { FaInstagram, FaDiscord, FaTwitterSquare  } from 'react-icons/fa';

function SocialStickyContainer() {
  return (
    <div className="sticky-social-icon-container">
            <ul>
                <li>
                    <a href="#" target="_blank">
                           <FaDiscord className="fa-brands fa-discord"/>
                      
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/3ko_89" target="_blank">
                        <FaInstagram className="fa-brands fa-instagram"/>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/3ko89" target="_blank">
                        <FaTwitterSquare className="fa-brands fa-twitter-square"/>
                    </a>
                </li>
            </ul>
        </div>
  )
}

export default SocialStickyContainer
