import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/globalStyles';
import '../styles/style.css';
import  '../styles/section-post-content.css'
import logo from '../imgs/3ko-89_logo_site.png';
import img3k089 from '../imgs/3K-0-89-NFT-002_Small_4.png'
import smoke from '../imgs/smoke.png'

function PostSection() {
    
  return (
      
        <section className="section-post-content" id="section_post_content">
            <div className="section-post__content  row p-sm-0">
                <div className="section-post__img col-md-6">
                    <div  >
                          <img className="section-post-content-site-logo" src={logo} alt="section-post-content-site-logo"/>
                    <div className="my-3 section-post-content-left-text">
                          <span>An NFT collection of 10,000 unique robots<br></br></span>
                    </div>
                    <div className="my-5 btn-section-discord-wrapper">
                        <a href="#section-choosenft" className="btn-section-discord">MINT YOURS NOW!</a>
                    </div> 
                    </div>         
                </div>
                <div className="col-md-6  section-post-content-divimg ">
                    <img className="img-section-post-content-bot" src={img3k089} alt="img3k089"/>    
       
        <img className="smoke-cloud" id="smoke_${i}" src={smoke} alt="smoke-cloud" />
    
                </div>
                <div className="row section-post-content__div">
                    <div className="col-12 section-post-content-desc2">
                        <span>I'm Holding the Magic Keys for Higher Dimensions</span>
                    </div>
                </div>
            </div>
            <div className="overlayer" id="overlayer_smoke"></div>
        </section>
  )
}

export default PostSection
