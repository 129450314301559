import React from 'react'
import '../styles/faqsTerms.css'
import '../styles/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function Faqs() {
    return ( <div>
      <div className="term_section">
        <div className="container team-container">
          <div className="term_title">
            <h1>FAQs</h1>
            <hr/>
          </div>
          <div className="term_body">
            <div className="term_body_section">
              <div className="each-section">
                <h2 className="font-weight-bold">Collectors</h2>
                <h4 className="font-weight-bold">What is 3K-O-89?</h4>
                <p>3K-O-89 is a collection of 10,000 unique and vintage robot NFTs with different outfits, based on the history of the greatest human empires and beyond.</p>
                <p>Each 3K-O-89 is unique since it was created 33 years ago and has evolved and acquired characteristics of today’s times.</p>
                <p>3K-O-89 is based on hundreds of well-designed elements that characterize a bullish NFT enthusiast.</p>
                <p>Understanding 3K-O-89 means joining a family full of people who believe in the future of cryptocurrencies, decentralize system, and blockchain technology.</p>
              </div>
              <div className="each-section">
                <h4 className="font-weight-bold">What is an NFT?</h4>
                <p>An NFT (non-fungible token) is a digital collectible or item that is unique and has exactly one owner, guaranteed and visible to everyone.</p>
                <p>NFTs can represent any digital asset Including art, audio, videos, items in video games and other forms of creative work.</p>
                <p>From a more technical standpoint, an NFT is a unit of data on a digital ledger or distributed database called a blockchain. Each NFT represents a unique digital item that’s non-divisible and is therefore not interchangeable.</p>
                <p>This makes them very similar to cryptocurrencies such as Ethereum. However, an NFT is completely unique.</p>
                <p>Its file stores extra information that elevates it above pure currency.</p>
              </div>
              <div className="each-section">
                <h4 className="font-weight-bold">Why are NFT’s so valuable?</h4>
                <p>NFTs are valuable because they verify the authenticity of a non-fungible asset. This makes the assets unique and one of a kind.</p>
                <p>For example, Vincent Van Gogh’s paintings are non-fungible. While anyone can make copies of his paintings, the original painting remains irreplaceable and unique.</p>
                <p>Likewise, NFTs make digital content irreplaceable.</p>
              </div>
              <div className="each-section">
                <h4 className="font-weight-bold">What kinds of NFTs will I find here?</h4>
                <p>On 3K-O-89, you will be able to collect NFTs from the original creator, illustrator artists, and 3D designers and find artwork that is exclusively created for our marketplace.</p>
                <p>The creator and the team will donate a percentage of their net profits to charities in the origin country.</p>
              </div>
              <div className="each-section">
                <h4 className="font-weight-bold">How can I keep up to date with 3K-O-89 NFT releases and news?</h4>
                <p>For the latest information or to engage in conversation about them, please follow us on;</p>
                <ul>
                  <li><a href="#" target="_blank">Discord</a></li>
                  <li><a href="https://twitter.com/3ko89" target="_blank">Twitter</a></li>
                  <li><a href="https://www.instagram.com/3ko_89" target="_blank">Instagram</a></li>
                </ul>
              </div>
              <div className="each-section">
                <h4 className="font-weight-bold">What is Discord and how do I get it?</h4>
                <p>Discord is a free voice, video, and text chat application that’s used by tens of millions of people to talk and hang out with their communities and friends.</p>
                <p>At LastKnown, we use Discord to discuss our artist releases and industry news, as well as interact directly with our artists.</p>
                <p>To download Discord, open your web browser and go to <a href="http://www.discordapp.com" target="_blank"> http://www.discordapp.com .</a> Click on “Download” in the top-left corner of your screen and click on the button that corresponds to your PC’s operating system, i.e., Windows, Linux or Mac.</p>
              </div>
              <div className="each-section">
                <h4 className="font-weight-bold">How do I trade my NFT’s?</h4>
                <p>To sell your NFT, you must list the token on a secondary marketplace like OpenSea.</p>
                <p>To do this, visit the <a href="https://opensea.io/" target="_blank">OpenSea website </a> , click on the NFT in your collection that you’d like to sell and locate the “sell” button.</p>
                <p>Clicking on “sell” will bring up a pricing page, allowing you to set the details of the sale. You can choose either a fixed price or an auction sale.</p>
                <p>Hang tight, 3K-O-89 will be coming out with a secondary marketplace of its own in the near future.</p>
              </div>
              <hr/>

              <div className="each-section">
                <h2 className="font-weight-bold">Creators</h2>
                <h4 className="font-weight-bold">How does the 3K-O-89 marketplace work?</h4>
                <p>3K-O-89 is a niche marketplace which offers artists creative development and marketing services as well as unique representation.</p>
                <p>We schedule exclusive creator drops and build hype around them.</p>
              </div>
              <div className="each-section">
                <h4 className="font-weight-bold">What should I think about when creating art for 3K-O-89?</h4>
                <p>When considering how to craft an NFT collection for 3K-O-89, there’s a fair amount of strategy beyond the actual art pieces.</p>
                <p>Nevertheless, planning is key. Among the key factors you should consider during the art creation Include:</p>
                <p>1. Project: It’s important to have a clear idea of the nature of your project and its end goal. What kind of multimedia are you using? Who or what does the project represent? What kind of value does it create? How will you distinguish it from other NFTs?</p>
                <p>2. Pricing: Don’t overprice your NFT, particularly if you are new to the space. Instead, consider its value proposition and utility, your fan base and audience’s loyalty.</p>
                <p>3. Exclusivity: Our creators produce a limited supply of NFTs to take advantage of consumers’ perceived scarcity and to drive up value. We highly encourage this.</p>
                <p>4. Audiences: Who is your fan base? Define and identify your target audience.</p>
              </div>
              <div className="each-section">
                <h4 className="font-weight-bold">What is NFT minting?</h4>
                <p>When you decide to create an NFT, you must first “mint” the digital version of your artwork.</p>
                <p>Minting an artwork refers to the act of tokenizing the artwork, uploading it to 3K-O-89 and issuing a token to guarantee its authenticity.</p>
              </div>
               <hr/>
              <div className="each-section">
                <h2 className="font-weight-bold">Roadmap</h2>
                <p>3K-O-89 is a space robot that has returned to earth to share the keys to higher dimensions and other secrets about this mystical world.</p>
                <p>After a long creative process and a guarantee of excellence, 3K-O-89 presents itself as an NFT.</p>
                <h4 className="font-weight-bold">The 3K-O-89 NFTs collection roadmap is as follows:</h4>
                <p>We will highlight our followers, who will be an active part of our social media channels, including Twitter, Instagram, and Discord. Register and follow us on our social networks to participate in this project.</p>
                  <ul>
                    <li><strong className="font-weight-bold">Whitelisted spots for active members that reach level 3 in our Discord community: (Expired)</strong>
                        <p>If you are an active member of our social media, you will have the opportunity to climb positions in our discord community. If you reach level 3, you will be whitelisted automatically, and you will get a code to receive a free NFT (gas fees not included). (Check our Discord channel for details by clicking
                           <a href="#" target="_blank"> here</a>)</p> </li>
                    <li><strong className="font-weight-bold">Gifts:</strong>
                        <p>As an active community member, you will be automatically registered in raffles where you’ll stand a chance to win a free NFT (gas fees not included) and various gifts (e.g., t-shirts, keychains, hats, stickers, etc.).</p>
                    </li>
                    <li><strong className="font-weight-bold">Contests:</strong>
                        <p>During our 3K-O-89 NFTs pre-launch and reveal, you’ll have the opportunity to participate in a contest where you can win a free 3K-O-89 NFT (gas fees not included).</p>
                        <p><em><strong className="font-weight-bold">We are planning to make a 3K-O-89 animated story for the Sandbox metaverse</strong></em>.</p> 
                        <h5 className="font-weight-bold">The contest participation process is as follows:</h5>
                        <p>1. Our followers will send us their written stories for evaluation. We will choose the best three stories for a free NFT (gas fees not included).</p>
                        <p>2. From the three finalists, we will choose the best as a footing of our 3K-O-89 animated story for the Sandbox metaverse, and the chosen one will also get a free NFT! (Gas fees not included).</p>
                    </li>
                    <li><strong className="font-weight-bold">Launch of the 3K-O-89 collection:</strong>
                      <p><strong className="font-weight-bold">Whitelist:</strong> Ranking level three on Discord</p>
                      <p><strong className="font-weight-bold">1000 NFT for pre-sale:</strong> Discount sale (maximum of 6 NFTs per Wallet)</p>
                      <p><strong className="font-weight-bold">Public Sale:</strong> Launch price for each NFT</p>
                    </li>
                    <li><strong className="font-weight-bold">Sandbox 3D Avatar:</strong>
                      <p>We will be giving away a 3K-O-89 NFT Sandbox 3D animated Avatar to one lucky winner among the first 1000 3K-O-89 NFT* buyers from the public sale. 
                      (*Holder must register their token in our Discord community).</p>
                    </li>
                    <li><strong className="font-weight-bold">Raffle for the NFT holders:</strong>
                      <p>Our Discord channel will announce the release and the raffle date for the physical and real-world 3K-O-89 collectible model. It will be printed in 3D using PLA+ (Poly) Filament material and approximately 6 to 8 inches tall.</p>
                      <p>We will raffle 50 NFTs* from the second collection among our first collection-holders in a metaverse VIP event. The date will be announced, and the NTF holder must register their token in our Discord community (*Paying only the gas fee).</p>
                  </li>
                  <li><strong className="font-weight-bold">Metaverse land percentage (%) of ownership*:</strong>
                    <p>A 3K-O-89 NFT Holder will have the opportunity to earn a percentage (%)** ownership of our metaverse land or a percentage (%) of the net profit from our first collection. Choosing either option will be subject to a vote, which will occur later. (*Holder must register their token in our Discord community) (**Subject to availability).</p>
                  </li>
                  <li><strong className="font-weight-bold">Giving back to the community</strong>
                    <p>Mauricio (the creator) and most of the team were born and raised in Colombia (South America). Although they have lived abroad, they remain attached to their Colombian customs and traditions. They also share a deep desire to see their people find opportunities to progress and improve their lives. Therefore, they will contribute a percentage (%) of the net profits to Colombian social organizations.</p>
                  </li>
               </ul>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default Faqs
