import React, {
  useState,
  useRef, useEffect
} from 'react'
import { Route, Routes } from 'react-router-dom'
import './styles/reset.css'
import './styles/style.css'
import Navbar from './components/Navbar'
import PostSection from './components/PostSection'
import WhitelistSection from './components/WhitelistSection'
import HelloSection from './components/HelloSection'
import ConnectDescriptionSection from './components/ConnectDescriptionSection'
import IntroduceCreatorSection from './components/IntroduceCreatorSection'
import NFTsection from './components/NFTsection'
import RaritySection from './components/RaritySection'
import SectionMinted from './components/SectionMinted'
import BackinTimesection from './components/BackinTimesection'
import TeamSection from './components/TeamSection'
import Footer from './components/Footer'
import SocialStickyContainer from './components/SocialStickyContainer'
import TopScroll from './components/TopScroll'
import Faqs from './components/Faqs'
import Terms_conditions from './components/Terms_conditions'

function App () {
  require('dotenv').config();
  const axios = require('axios')
  
  const [discinput, setDiscInput] = useState('')
  const [dropdown, setDropdown] = useState(false)

  const [account, setCurrentAccount] = useState('')
  const { ethereum } = window
  const [clickedBtnNav, setClickedBtnNav] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [keyResponse, setKeyResponse]= useState(false)
  const [whiteList, setWhiteList] = useState(false)
  const [presale, setPresale] = useState(false)
  const [fechaWhiteList, setFechaWhiteList] = useState(false)

const dataServer = () =>{
  /**request */
  const data = {
       account: account,
       discordCode: discinput
  }

    // send the request
    if(account && discinput){
        axios.post(`${process.env.REACT_APP_API_SERVER}/key`, data)
        .then(response => {
          setKeyResponse(response.data);
        })
        .catch(err=> {
          console.log("review internal seed code, and account")
    })
    }else{
       console.log("review account o discinput undefined")
    }
}

const dataPresale = () =>{
    // send the request
            axios.post(`${process.env.REACT_APP_API_SERVER}/dataPresale`)
        .then(response => {
          setPresale(response.data);
        })
        .catch(err=> {
          console.log("review internal presale")
    })
}

const dataFechaWhitelist = () =>{
    // send the request
        axios.post(`${process.env.REACT_APP_API_SERVER}/dataFechaWhitelist`)
        .then(response => {
          setFechaWhiteList(response.data);
        })
        .catch(err=> {
          console.log("review internal WhiteList")
    })
}

useEffect(()=>{
    dataServer()
},[account, discinput])

  useEffect(()=>{
    funcGetDiscInput()
    dataFechaWhitelist()
    dataPresale()
  }, [])
  
  const funcGetDiscInput =(value)=>{
    setDiscInput(value)
    return value
}

  const getDataFromChild = async clickedBtnNav => {
    setClickedBtnNav({ clickedBtnNav: clickedBtnNav })
    if (clickedBtnNav === true) {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

      if (accounts[0]) {
        setCurrentAccount(accounts[0])
          setClickedBtnNav(true)
      }else {
        setClickedBtnNav(false)
      }
      return clickedBtnNav
    }
  }

    const changeActive = () => {
    $('.menu-item').removeClass('menu-selected')
    $(this).addClass('menu-selected')
  }

  const changedropdown = () => {
    const currentDropdown = dropdown
    setDropdown(!currentDropdown)
  }

  const myRef = useRef(null)

  const scrollToTop = () => {
    myRef.current.scrollTo(0, 0)
  }

  const handleChange = clicked => {
    setClicked(clicked)
    return clicked
  }

  return (
    <div className='root' ref={myRef} id='myRef'>
      <Routes>
        <Route
          path='/'
          element={
            <>
              <Navbar
                dropdown={dropdown}
                clickMe={changedropdown}
                getDataFromChild={getDataFromChild}
                clickedBtnNav={clickedBtnNav}
                handleChange={handleChange}
                clicked={clicked}
                account={account}
              />
              <PostSection />
              <WhitelistSection />
              <ConnectDescriptionSection />
              <HelloSection />
              <RaritySection />
              <NFTsection />
              <SectionMinted
                discinput={discinput}
                getDataFromChild={getDataFromChild}
                clickedBtnNav={clickedBtnNav}
                handleChange={handleChange}
                clicked={clicked}
                funcGetDiscInput={funcGetDiscInput}
                account={account}
                keyResponse={keyResponse}
                whiteList={whiteList}
                presale={presale}
                fechaWhiteList={fechaWhiteList}
                />
              <IntroduceCreatorSection />
              <TeamSection />
              <Footer />
              <SocialStickyContainer />
              <TopScroll scrolltop={scrollToTop} />
            </>
          }
        />

        <Route
          path='/faqs.html'
          element={
            <>
              <Navbar />
              <Faqs />
              <Footer />
              <SocialStickyContainer />
              <TopScroll scrolltop={scrollToTop} />
            </>
          }
        />

        <Route
          path='/terms-conditions.html'
          element={
            <>
              <Navbar />
              <Terms_conditions />
              <Footer />
              <SocialStickyContainer />
              <TopScroll scrolltop={scrollToTop} />
            </>
          }
        />
      </Routes>
    </div>
  )
}

export default App
