import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../styles/section-choose-nft.css'
import '../styles/style.css'
import img100 from '../imgs/100.png'
import img102 from '../imgs/102.png'
import img111 from '../imgs/111.png'
import img115 from '../imgs/115.png'
import img1000 from '../imgs/100.png'
import img1023 from '../imgs/102.png'
import img1028 from '../imgs/111.png'
import img1037 from '../imgs/115.png'
import img1045 from '../imgs/100.png'
import img1058 from '../imgs/102.png'
import img1065 from '../imgs/111.png'
import img1071 from '../imgs/115.png'

function Images() {
    const imgs = [
      {src: `${img100}`, key: 1, marginLeft: '0'},
      { src: `${img102}`, key: 2, marginLeft: '26%'},
      { src: `${ img111}`, key: 3, marginLeft: '52%' },
      { src: `${img115}`, key: 4, marginLeft:'78%'},
      {src: `${img1000}`, key: 5, marginLeft: '101%'},
      { src: `${img1023}`, key: 6, marginLeft: '101%'},
      { src: `${img1028}`, key: 7, marginLeft: '101%' },
      { src: `${img1037}`, key: 8, marginLeft: '101%'},
      { src: `${img1045}`, key: 9, marginLeft: '101%'},
      { src: `${img1058}`, key: 10, marginLeft: '101%' },
      { src: `${img1065}`, key: 11, marginLeft: '101%'},
      { src: `${img1071}`, key: 12, marginLeft: '101%' }
   ];
  return (
     <div className="d-i-content w-100 " style={{overflow:'hidden'}}>
            {imgs.map(e => {
                 return(    
            <div className="carousel-item active col-md-4  clearfix w-25  moveDivImg" key={e.key}  style={{marginLeft:e.marginLeft}} >  
            <img src={e.src} key={e.key} 
     className= "card-img-top" alt="img"/>
                                        </div>
                                )
                                })}
                            </div>
  )
}

export default Images
