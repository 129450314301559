import React from 'react'
import  img3ko89rarity from '../imgs/3ko89_rarity_new.gif' 
import '../styles/sectionRarity.css'
import '../styles/style.css'

function RaritySection() {
  return (
     <section className="section-rarity py-5">
            <h2>Rarity</h2>
            <div className="row mt-lg-5 mt-md-5 mt-sm-5 mt-1">
                <div className="col-md-4 text-start">
                    <img src={img3ko89rarity} alt="img3ko89rarity" />
                    <div className="desc mt-3">Rarity Sample Animation</div>
                </div>
                <div className="rarity-row col-md-8 text-start d-flex justify-content-center align-items-start">
                    <div className="mx-4">
                          <p><span>10</span> Backgrounds</p>
                          <p><span>12</span> Places</p>
                          <p><span>14</span> Platforms</p>
                             <p><span>13</span> Backpacks</p>
                          <p><span>15</span> Capes</p>
                          <p><span>10</span>  Bodies</p>
                          <p><span>23</span> Body Armor</p>
                    </div>
                    <div className="mx-4">
                        <p><span>20</span> Shoulder Guards</p>
                        <p><span>17</span> Arm Guards</p>
                        <p><span>39</span> Shin Guards</p>
                         <p><span>41</span> Goggles</p>
                        <p><span>19</span> Belts</p>
                        <p><span>36</span> Helmets</p>
                        <p><span>21</span> Weapons</p>
                    </div>
                </div>
                <div className="bottom-desc">
                    <p>
                        All 3K-O-89 are cool, but some are a little bit cooler. Once reveal day rolls around, you’ll be able to see how rare your 3ko89 is. Excited? You should be.
                    </p>
                    <p>Your rarity also will have more utility in the future.</p>
                </div>
            </div>
        </section>
  )
}

export default RaritySection
